import { app } from "../../configuration/configuration";
import UserMenu from "./UserMenu";
import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";

export default function NavBar() {
  return (
    <nav className="fixed top-0 z-20 h-16 w-full animate-fade-down border-b border-gray-100 bg-white pl-16 animate-duration-500">
      <div className="flex h-16 items-center px-6">
        <div className="text-lg font-medium uppercase text-slate-500">
          {app.name}
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center">
          <UserMenu />
          <LanguageSwitcher />
        </div>
      </div>
    </nav>
  );
}
