import decoder from "./ResponseParserUtils";
import CryptoJS from "crypto-js";
import pako from "pako";

type EncryptedPayload = {
  iv: string;
  value: string;
}

type ParsedPayload = {
  key: string;
  encrypted: EncryptedPayload;
}

export default class ResponseParser {
  static parse(payload: string) {
    const data = CryptoJS.enc.Base64.parse(payload).toString(CryptoJS.enc.Utf8);
    const { offset, value, length } = ResponseParser.parseOffset(data);
    const {
      key,
      encrypted
    } = ResponseParser.parsePayload(value, offset, length);
    const decrypted = ResponseParser.decryptPayload(encrypted, key);
    return JSON.parse(decrypted);
  }

  static parseOffset(payload) {
    let config = payload.split(".");
    let chars = CryptoJS.enc.Base64.parse(config[0]).toString(
      CryptoJS.enc.Utf8
    );

    if (chars.length !== 32) {
      throw new Error("Incorrect meta length.");
    }

    let offset = chars.charCodeAt(0);
    let length = chars.charCodeAt(1);
    let value = config[1];

    return { offset, value, length };
  }

  static parsePayload(payload: string, offset: number, length: number): ParsedPayload {
    const data =
      payload.substring(0, offset) + payload.substring(offset + length);
    const key = payload.substring(offset, offset + length);
    const encryptedString = CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
    const encrypted = JSON.parse(encryptedString) as EncryptedPayload;
    return { encrypted, key };
  }

  static decryptPayload(payload: EncryptedPayload, k: string) {
    const iv = CryptoJS.enc.Base64.parse(payload.iv);
    const key = CryptoJS.enc.Utf8.parse(k);

    let decryptedData = CryptoJS.AES.decrypt(payload.value, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC
    });

    return pako.inflate(decoder(decryptedData), {
      to: "string"
    });
  }
}
