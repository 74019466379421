import Swal, { SweetAlertOptions } from "sweetalert2";

export default class Notifications {
  static fatal(message: string) {
    Swal.fire({
      toast: true,
      icon: "error",
      position: "top-end",
      text: message,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timer: 1000,
      didOpen: () => {
        Swal.stopTimer();
      },
      customClass: {
        popup: "!bg-red-50"
      }
    });
  }

  static info(message: string) {
    Swal.fire({
      toast: true,
      icon: "info",
      position: "top-end",
      text: message,
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false
    });
  }

  static warning(message: string) {
    Swal.fire({
      toast: true,
      icon: "warning",
      position: "top-end",
      text: message,
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false
    });
  }

  static success(message: string) {
    Swal.fire({
      toast: true,
      icon: "success",
      position: "top-end",
      text: message,
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false,
      customClass: {
        popup: "!bg-green-50"
      }
    });
  }

  static error(error: any) {
    console.error(error);
    Swal.fire({
      toast: true,
      icon: "error",
      position: "top-end",
      text: typeof error === 'string' ? error : error.message,
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false
    });
  }

  static confirm(title: string, text: string, confirmButtonText: string, cancelButtonText: string) {
    return Swal.fire({
      title,
      text,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
      reverseButtons: true
    });
  }

  static alert(title: string, text: string) {
    return Swal.fire({
      title,
      text
    });
  }

  static raw(configuration: SweetAlertOptions) {
    return Swal.fire(configuration);
  }
}
