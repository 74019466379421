import React, { FC } from "react";
import { LoadableComponent } from "@loadable/component";
import PageLoader from "../feature/common/loaders/PageLoader";

type SuspenseComponentProps = {
  fallback: React.ReactNode;
};

export default (
  Component: FC<SuspenseComponentProps> | LoadableComponent<any>,
) => {
  const WithSuspense = function () {
    return <Component fallback={<PageLoader title="" subtitle="" active />} />;
  };

  return WithSuspense;
};
