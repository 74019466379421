import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useIdentity } from "../hooks/IdentityProvider";
import { LoadableComponent } from "@loadable/component";
import { app } from "../configuration/configuration";
import { useTranslation } from "react-i18next";
import Notifications from "../classes/utilities/Notifications";

const withAuthorization = (Component: FC | LoadableComponent<any>) => {
  const WithAuthorization = function () {
    const { identity } = useIdentity();
    const { pathname } = useLocation();
    const { t } = useTranslation();

    if (identity === null) {
      return <Navigate to="/auth/login" replace={true} />;
    }

    const { user, workspace, properties } = identity;

    if (!user.terms_accepted && !app.tosExcludedPaths.includes(pathname)) {
      return <Navigate to="/settings/terms" replace={true} />;
    }

    if (
      !workspace.has_subscription &&
      !properties.is_demo &&
      app.redirectToOnboarding.includes(pathname)
    ) {
      Notifications.info(t("messages.subscription_required"));
      return <Navigate to="/onboarding/subscription/create" replace={true} />;
    }

    return <Component />;
  };

  return <WithAuthorization />;
};

export default withAuthorization;
