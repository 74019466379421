import { useTranslation } from "react-i18next";

export type LoaderProps = {
  title?: string;
  subtitle?: string;
};

export default function Loader(props: LoaderProps) {
  const { t } = useTranslation();
  const subtitle = props.subtitle ?? t("loader.pleaseWait");
  const title = props.title ?? t("loader.loading");

  return (
    <div className="flex flex-col items-center animate-duration-1000">
      <div className="h-12 w-12 animate-spin rounded-full border-8 border-slate-300 border-b-slate-400" />
      {title && <div className="mt-2 text-lg text-slate-500">{title}</div>}
      {subtitle && <div className="text-sm text-slate-400">{subtitle}</div>}
    </div>
  );
}
