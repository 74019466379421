import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "../classes/api";
import { Identity } from "../classes/api/types";
import Notifications from "../classes/utilities/Notifications";
import WebNotifications from "../classes/utilities/web-notifications";
import FullLoader from "../feature/common/loaders/FullLoader";
import i18n from "../i18n";

const AuthContext = createContext<AuthenticationProviderContext>(null);

type AuthenticationProviderContext = {
  identity: Identity | null;
  ready: boolean;
  loadIdentity: () => Promise<void>;
};

export function IdentityProvider(props: PropsWithChildren) {
  const [identity, setIdentity] = useState<Identity | null>(null);
  const [ready, setReady] = useState(false);

  const initializeApi = async () => {
    return api.getCsrfCookie();
  };

  const loadIdentity = async () => {
    const identity = await api.identity();
    setIdentity(identity);
  };

  const initialize = async () => {
    try {
      await initializeApi();
      await loadIdentity();
      setReady(true);
    } catch (error) {
      console.error(error);
      Notifications.fatal(i18n.t("messages.fatal"));
    }
  };

  const value = { identity, ready, loadIdentity };

  useEffect(() => {
    WebNotifications.on("identity-updated", loadIdentity);
    initialize();

    return () => {
      WebNotifications.off("identity-updated", loadIdentity);
    };
  }, []);

  useEffect(() => {
    if (!identity) {
      return;
    }

    WebNotifications.subscribe(identity.workspace.ulid);

    return () => {
      WebNotifications.unsubscribe();
    };
  }, [identity]);

  if (!ready) {
    return <FullLoader active={true} />;
  }

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

export function useIdentity() {
  return useContext(AuthContext);
}
