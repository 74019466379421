import { Cog6ToothIcon, FireIcon } from "@heroicons/react/24/solid";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { navigation } from "../../configuration/navigation";

export default function SideBar() {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div
      className="fixed bottom-0 left-0 top-0 z-[100] flex w-16 animate-fade-right flex-col bg-indigo-500 animate-duration-500">
      <Tooltip
        id="sidenav-tooltip"
        place="right"
        variant="info"
        style={{ zIndex: 100 }}
      />
      <div className="flex h-16 w-16 items-center justify-center bg-indigo-700">
        <FireIcon
          className="h-8 w-8 text-white transition-all duration-500 ease-in-out animate-duration-500 hover:rotate-[360deg] hover:animate-wiggle-more hover:animate-infinite" />
      </div>
      <div className="flex-1 pt-2">
        {navigation.items.map((item, index) => {
          const Icon = item.icon;
          const active = location.pathname.startsWith(item.route);

          return (
            <Link
              key={index}
              to={item.route}
              data-tooltip-id="sidenav-tooltip"
              data-tooltip-content={item.label}
              className={classNames(
                "m-2 flex h-12 w-12 items-center justify-center rounded-lg transition hover:bg-indigo-600",
                {
                  "bg-indigo-400": active
                }
              )}
            >
              <Icon className="h-6 w-6 text-white" />
            </Link>
          );
        })}
      </div>
      <div>
        <Link
          to="/settings"
          data-tooltip-id="sidenav-tooltip"
          data-tooltip-content={t("navigation.settings")}
          className={classNames(
            "m-2 flex h-12 w-12 items-center justify-center rounded-lg transition hover:bg-indigo-600",
            {
              "bg-indigo-400": location.pathname.startsWith("/settings")
            }
          )}
        >
          <Cog6ToothIcon className="h-6 w-6 text-white" />
        </Link>
      </div>
    </div>
  );
}
