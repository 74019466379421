import { IdentityProvider } from "./hooks/IdentityProvider";
import { RouterProvider } from "react-router-dom";
import React from "react";
import AppRouter from "./AppRouter";
import "./i18n/index";

// Styles
import "react-tooltip/dist/react-tooltip.css";

export default function App() {
  return (
    <>
      <IdentityProvider>
        <RouterProvider router={AppRouter} />
      </IdentityProvider>
    </>
  );
}
