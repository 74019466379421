import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route
} from "react-router-dom";
import Layout from "./feature/layout/Layout";
import React from "react";
import * as Pages from "./pages";

// HOC
import withAuthedAndSuspense from "./hocs/withAuthedAndSuspense";
import withOnlyGuest from "./hocs/withOnlyGuest";
import withSuspense from "./hocs/withSuspense";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<Pages.Error />}>
      <Route index element={<Navigate to="/dashboard" />} />
      <Route element={<Layout name="empty" />} errorElement={<Pages.Error />}>
        <Route
          path="auth/login"
          element={withOnlyGuest(withSuspense(Pages.Login))}
        />
        <Route
          path="auth/logout"
          element={withAuthedAndSuspense(Pages.Logout)}
        />
      </Route>
      <Route element={<Layout name="base" />} errorElement={<Pages.Error />}>
        <Route
          path="/dashboard"
          element={withAuthedAndSuspense(Pages.Dashboard)}
        />
        <Route
          path="/settings"
          element={withAuthedAndSuspense(Pages.Settings)}
        />
        <Route
          path="/settings/terms"
          element={withAuthedAndSuspense(Pages.SettingsTerms)}
        />
        <Route
          path="/onboarding/subscription/create"
          element={withAuthedAndSuspense(Pages.SubscriptionCreatePage)}
        />
        <Route
          path="/onboarding/subscription/success"
          element={withAuthedAndSuspense(Pages.SubscriptionSuccessPage)}
        />
        <Route
          path="/resource/:resource"
          element={withAuthedAndSuspense(Pages.ResourceIndex)}
        />
        <Route
          path="/resource/:resource/create"
          element={withAuthedAndSuspense(Pages.ResourceCreate)}
        />
        <Route
          path="/resource/:resource/:id"
          element={withAuthedAndSuspense(Pages.ResourceShow)}
        />
        <Route
          path="/resource/:resource/:id/edit"
          element={withAuthedAndSuspense(Pages.ResourceEdit)}
        />
      </Route>
    </Route>
  )
);

export default router;
