import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import BaseLayout from "./BaseLayout";
import EmptyLayout from "./EmptyLayout";

const layouts: Record<string, FC> = {
  base: BaseLayout,
  empty: EmptyLayout,
};

type LayoutProps = {
  name?: string;
  useChildren?: boolean;
};

const getLayout = (name: string) => {
  if (!layouts[name]) {
    return layouts.base;
  }
  return layouts[name];
};

export default function Layout(props: PropsWithChildren<LayoutProps>) {
  const PageLayout: FC<PropsWithChildren<LayoutProps>> = getLayout(props.name);
  const useChildren = !!props.useChildren;

  return (
    <PageLayout {...props}>
      {useChildren && props.children}
      {!useChildren && <Outlet />}
    </PageLayout>
  );
}
