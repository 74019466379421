import loadable from "@loadable/component";

export const Logout = loadable(() => import("./feature/auth/LogoutPage"));
export const Login = loadable(() => import("./feature/auth/LoginPage"));
export const Dashboard = loadable(
  () => import("./feature/dashboard/DashboardPage")
);
export const Settings = loadable(
  () => import("./feature/settings/SettingsPage")
);

export const SettingsTerms = loadable(
  () => import("./feature/settings/SettingsTermsOfServicePage")
);

export const Error = loadable(() => import("./feature/error/ErrorPage"));

// Resources
export const ResourceIndex = loadable(
  () => import("./feature/resource/ResourceIndexPage")
);

export const ResourceEdit = loadable(
  () => import("./feature/resource/ResourceEditPage")
);

export const ResourceCreate = loadable(
  () => import("./feature/resource/ResourceCreatePage")
);

export const ResourceShow = loadable(
  () => import("./feature/resource/ResourceShowPage")
);

export const SubscriptionCreatePage = loadable(
  () => import("./feature/onboarding/SubscriptionCreatePage")
);

export const SubscriptionSuccessPage = loadable(
  () => import("./feature/onboarding/SubscriptionSuccessPage")
);
