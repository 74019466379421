import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useIdentity } from "../hooks/IdentityProvider";
import { app } from "../configuration/configuration";
import { LoadableComponent } from "@loadable/component";

export default function withOnlyGuest(Component: FC | LoadableComponent<any>) {
  const WithOnlyGuest = (props: any) => {
    const { identity } = useIdentity();

    if (identity) {
      return <Navigate to={app.homeRoute} />;
    }

    return <Component {...props} />;
  };

  return <WithOnlyGuest />;
}
