import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { EventEmitter } from "events";
import { app } from "../../configuration/configuration";
import api from "../api";

declare global {
  interface Window {
    Pusher: typeof Pusher;
  }
}

window.Pusher = Pusher;

class WebNotifications extends EventEmitter {
  private echo: Echo;
  private workspace: string | null;

  constructor() {
    super();
    this.workspace = null;

    this.echo = new Echo({
      broadcaster: "pusher",
      key: app.pusher.appKey,
      cluster: app.pusher.cluster,
      //forceTLS: true,
      authorizer: this.authorizer,
    });
  }

  authorizer(channel, options) {
    return {
      authorize: async (socketId, callback) => {
        api
          .authorizeBroadcast(socketId, channel.name)
          .then((response) => {
            callback(null, response.data)
          })
          .catch((error) => callback(error));
      },
    };
  }

  subscribe(workspace) {

    if (typeof workspace === "string" && workspace.length > 0) {
      this.workspace = workspace;
    }

    if (this.workspace === null) {
      console.warn(
        "WebNotifications: Skipping registration because workspace is invalid.",
      );
      return;
    }

    this.echo
      .private(`workspace.${workspace}`)
      .listen("SPANotificationEvent", (event) => {
        this.emit(event.payload.notification, event.payload);
      });
  }

  unsubscribe() {
    this.echo.leave(`workspace.${this.workspace}`);
    this.workspace = null;
  }
}

export default new WebNotifications();
