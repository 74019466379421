import enableMocking from "./mock/enabler";
import ReactDom from "react-dom/client";
import React from "react";
import App from "./App";

const container = document.getElementById("app-root");
const loader = document.getElementById("app-loader");
const root = ReactDom.createRoot(container);

const startApp = () => {
  root.render(React.createElement(App));
  loader.remove();
};

enableMocking().then(() => startApp());
