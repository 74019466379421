import { Fragment } from "react";
import { LanguageIcon } from "@heroicons/react/24/solid";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { languages } from "../../i18n";

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const switchLanguage = (language: string, close: () => void) => {
    close();
    i18n.changeLanguage(language);
  };

  return (
    <div className="relative ml-4">
      <Popover className="relative">
        {({ close }) => (
          <>
            <PopoverButton className="flex items-center justify-center text-xs font-light text-slate-600 hover:underline">
              <span className="uppercase">{i18n.language}</span>
            </PopoverButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute right-0">
                <div className="border-slate-150 shadow-soft flex flex-col rounded-lg border bg-white drop-shadow">
                  <ul className="font-regular text-slate-600">
                    {languages.map((language) => (
                      <li
                        key={language.code}
                        className="border-b last:border-none"
                      >
                        <button
                          onClick={() => switchLanguage(language.code, close)}
                          className="inline-flex w-full items-center px-4 py-2 transition-colors hover:text-slate-800 focus:text-slate-800"
                        >
                          <LanguageIcon className="mr-2 h-4 w-4" />
                          <span>{language.name}</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
