import ReactDom from "react-dom";
import Loader, { LoaderProps } from "./Loader";
import React, { useEffect, useState } from "react";
import classNames from "classnames";

type FullPreloaderProps = LoaderProps & {
  active?: boolean;
};

export default function FullLoader(props: FullPreloaderProps) {
  const [isVisible, setIsVisible] = useState(true);
  const root = document.querySelector("body");
  const active = props.active === true;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (active) {
      setIsVisible(true);
    } else {
      timeout = setTimeout(() => setIsVisible(false), 200);
    }

    return () => clearTimeout(timeout);
  }, [active]);

  if (!isVisible) {
    return null;
  }

  const portal = (
    <div
      className={classNames(
        "absolute inset-0 z-[1000] flex flex-col items-center justify-center bg-slate-100",
        {
          "animate-fade animate-reverse animate-duration-200": !active,
        },
      )}
    >
      <Loader {...props} />
    </div>
  );

  return ReactDom.createPortal(portal, root);
}
