export const forceEnvironment = "";

export const app = {
  environment: forceEnvironment || process.env.APP_ENVIRONMENT,
  name: "I ♡ Transcriptions",
  homeRoute: "/dashboard",
  titleSeparator: " → ",
  turnstileSiteKey:
    process.env.TURNSTILE_SITE_KEY || "1x00000000000000000000AA",
  tosExcludedPaths: ["/settings/terms", "/auth/logout", "/auth/login"],
  workspaceExcludedPaths: [
    "/resource/workspace",
    "/settings/terms",
    "/auth/logout",
    "/auth/login",
  ],
  redirectToOnboarding: [
    '/resource/transcriptions/create',
    '/dashboard',
],
  pusher: {
    appKey: "c6044178a8c7e25db828",
    cluster: "us2",
  },
};
