import { Fragment } from "react";
import {
  ArrowLeftOnRectangleIcon,
  Cog6ToothIcon,
  UserCircleIcon
} from "@heroicons/react/24/solid";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition
} from "@headlessui/react";
import { Link } from "react-router-dom";
import { useIdentity } from "../../hooks/IdentityProvider";
import { useTranslation } from "react-i18next";

export default function UserMenu() {
  const { t } = useTranslation();
  const { identity } = useIdentity();

  if (identity === null) {
    return null;
  }

  const { user } = identity;

  return (
    <div className="relative flex items-center">
      <div className="text-slate-600">{user.name}</div>
      <Popover className="relative">
        <PopoverButton
          className="ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-slate-200">
          {!user.avatar && <UserCircleIcon className="h- w-6 text-slate-600" />}
          {user.avatar && (
            <img
              src={user.avatar}
              alt="Avatar"
              className="h-8 w-8 rounded-full"
            />
          )}
        </PopoverButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <PopoverPanel className="absolute right-0 z-10">
            <div
              className="border-slate-150 shadow-soft mt-1 flex w-48 flex-col rounded-lg border bg-white drop-shadow">
              <div className="rounded-t-lg bg-slate-100 p-2 text-slate-600">
                <div className="mr-2 text-right">
                  <div className="md">{user.name}</div>
                  <div className="text-xs">{user.email}</div>
                </div>
              </div>
              <div className="p-2">
                <ul className="font-regular text-slate-500">
                  <li>
                    <Link
                      to="/settings"
                      className="inline-flex w-full items-center space-x-2 transition-colors hover:text-slate-800 focus:text-slate-800"
                    >
                      <Cog6ToothIcon className="h-4 w-4" />
                      <span>{t("userMenu.settings")}</span>
                    </Link>
                  </li>
                  <li className="m-0">
                    <hr className="my-1" />
                  </li>
                  <li>
                    <Link
                      to="/auth/logout"
                      className="inline-flex w-full items-center space-x-2 transition-colors hover:text-slate-800 focus:text-slate-800"
                    >
                      <ArrowLeftOnRectangleIcon className="h-4 w-4" />
                      <span>{t("userMenu.logout")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </PopoverPanel>
        </Transition>
      </Popover>
    </div>
  );
}
