const wordToByteArray = function(word, length) {
  let ba = [];
  let xFF = 0xff;

  if (length > 0) {
    ba.push(word >>> 24);
  }
  if (length > 1) {
    ba.push((word >>> 16) & xFF);
  }
  if (length > 2) {
    ba.push((word >>> 8) & xFF);
  }
  if (length > 3) {
    ba.push(word & xFF);
  }

  return ba;
};

const wordArrayToByteArray = function(wordArray, length = undefined) {
  if (
    wordArray.hasOwnProperty("sigBytes") &&
    wordArray.hasOwnProperty("words")
  ) {
    length = wordArray.sigBytes;
    wordArray = wordArray.words;
  }

  let result = [];
  let bytes;
  let i = 0;

  while (length > 0) {
    bytes = wordToByteArray(wordArray[i], Math.min(4, length));
    length -= bytes.length;
    bytes.forEach(function(byte) {
      result.push(byte);
    });
    i++;
  }

  return result;
};

export default wordArrayToByteArray;
