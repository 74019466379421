import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en/translation";
import es from "./locales/es/translation";
import ja from "./locales/ja/translation";
import { app } from "../configuration/configuration";

const resources = {
  en,
  es,
  ja
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    supportedLngs: ["en", "es", "ja"],
    debug: app.environment === "development",
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
      excludeCacheFor: ["cimode"],
      lookupLocalStorage: "lng",
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const languages = [
  {
    code: "es",
    name: "Español",
  },
  {
    code: "en",
    name: "English",
  },
  {
    code: "ja",
    name: "日本語",
  },
];
