import { app } from "../../configuration/configuration";
import { api } from "../../configuration/api";
import axios, {
  AxiosInstance,
  AxiosResponse,
  CreateAxiosDefaults,
} from "axios";
import { ApiResponse, EmptyResult } from "./response.types";
import { Identity, User } from "./types";
import i18n from "../../i18n";
import Notifications from "../utilities/Notifications";
import AppRouter from "../../AppRouter";
import ResponseParser from "../response-parser/ResponseParser";

export default class Api {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      withCredentials: true,
      withXSRFToken: true,
      baseURL: api.endpoint[app.environment],
      timeout: 60000,
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });
  }

  getCsrfCookie() {
    return this.withErrorHandling<EmptyResult>(() => this.axios.get("csrf"));
  }

  // Authentication
  authRedirect() {
    return this.withErrorHandling<User>(() => this.axios.get("auth/redirect"));
  }

  logout() {
    return this.withErrorHandling<User>(() => this.axios.get("auth/logout"));
  }

  // Identity
  identity() {
    return this.withErrorHandling<Identity>(() =>
      this.axios.get("auth/identity"),
    );
  }

  // Dashboard
  dashboard() {
    return this.withErrorHandling(() => this.axios.get("dashboard"));
  }

  settingsAcceptTerms(token: string) {
    return this.withErrorHandling(() => this.axios.post("tos", { token }));
  }

  // Subscription
  subscriptionGetPricesForProduct(productName: string) {
    return this.withErrorHandling(() =>
      this.axios.get(`subscription/prices/${productName}`),
    );
  }

  subscriptionCreate(productName: string, interval: string) {
    return this.withErrorHandling(() =>
      this.axios.post(`subscription/checkout`, {
        interval,
        name: productName,
      }),
    );
  }

  subscriptionUpdate() {
    return this.withErrorHandling(() => this.axios.get(`subscription/edit`));
  }

  // Rest methods
  listResource<T = any>(resource: string, filters: any) {
    return this.withErrorHandling<T>(() =>
      this.axios.get(resource, { params: filters }),
    );
  }

  showResource<T = any>(resource: string, id: string) {
    return this.withErrorHandling<T>(() => this.axios.get(`${resource}/${id}`));
  }

  createResource<T = any>(resource: string, data: any) {
    return this.withErrorHandling<T>(() =>
      this.axios.post(`${resource}`, data),
    );
  }

  updateResource<T = any>(resource: string, id: string, data: any) {
    return this.withErrorHandling<T>(() =>
      this.axios.put(`${resource}/${id}`, data),
    );
  }

  deleteResource<T = any>(resource: string, id: string) {
    return this.withErrorHandling<T>(() =>
      this.axios.delete(`${resource}/${id}`),
    );
  }

  authorizeBroadcast<T = any>(socketId: string, channelName: string) {
    return this.axios.post(`broadcasting/auth`, {
      socket_id: socketId,
      channel_name: channelName,
    });
  }

  requester(callback: (requester: AxiosInstance) => Promise<any>) {
    return this.withErrorHandling(() => callback(this.axios));
  }

  private async withErrorHandling<T>(
    request: () => Promise<AxiosResponse<ApiResponse<T>>>,
  ): Promise<T> {
    try {
      const response = await request();
      const { data } = response;

      if (response.status === 204) {
        return null as EmptyResult as T;
      }

      if (typeof data.redirect === "string") {
        if (data.redirect.startsWith("http")) {
          window.location.href = data.redirect;
        } else {
          await AppRouter.navigate(data.redirect);
        }
      }

      if (typeof data.reload === "boolean") {
        window.location.reload();
      }

      if (data.result === undefined) {
        throw new Error(i18n.t("messages.invalidServerResponse"));
      }

      if (data.message) {
        Notifications.info(data.message);
      }

      const requestId = response.headers["x-request-id"] || null;

      if (typeof data.result === "string" && requestId) {
        return ResponseParser.parse(data.result) as T;
      }

      return data.result;
    } catch (error) {
      if (error.response) {
        const response = error.response;

        if (response.status === 422) {
          const data = response.data;
          error.isValidationError = true;
          error.fields = data.errors;
        }

        if (response.data) {
          if (response.data.message) {
            Notifications.error(response.data.message);
          }
        }
      }
      throw error;
    }
  }
}
