import { forceEnvironment } from "../configuration/configuration";

export default async function enableMocking() {
  if (process.env.NODE_ENV === "development" && !forceEnvironment) {
    const { worker } = await import("./browser");
    return worker.start({
      onUnhandledRequest: "bypass"
    });
  }
}
